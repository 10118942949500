<template>
    <div class="card">
        <div class="card-header">Удалить?</div>
        <div class="card-body text-center">
            <p>Вы действительно хотите удалть {{item.sign > 0 ? 'Надбавку' : 'Штраф'}} {{item.name}}?</p>
            <p>{{item.description}}</p>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-6 text-left">
                    <button :disabled="loading" @click="$emit('close')" class="btn btn-success">Отмена</button>
                </div>
                <div class="col-6 text-right">
                    <button :disabled="loading" @click="deleteItem()" class="btn btn-danger">
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        Удалить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {BountyTypeMixin} from '@mixins';

    export default {
        name: 'DeleteForm',
        mixins: [BountyTypeMixin]
    }
</script>

<style scoped>

</style>
