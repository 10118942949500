<template>
    <div class="card mb-0">
        <div class="card-header">
            <template v-if="!!item.id">Изменить</template>
            <template v-else>Создать</template>
        </div>
        <div class="card-body mb-0">
            <div class="row">
                <div class="col-2">
                    <b-form-group description="Тип">
                        <select class="form-control" v-model="item.sign">
                            <option :value="1">Надбавка</option>
                            <option :value="-1">Штраф</option>
                        </select>
                    </b-form-group>
                    <error-list v-if="!!errors.sign" :message-function="errorMessageFormatter" :errors="errors.sign"></error-list>
                </div>
                <div class="col-4">
                    <b-form-group description="Название">
                        <input v-model="item.name" type="text" class="form-control">
                    </b-form-group>
                    <error-list v-if="!!errors.name" :message-function="errorMessageFormatter" :errors="errors.name"></error-list>
                </div>
                <div class="col-3">
                    <b-form-group description="Сумма">
                        <b-form-input type="number" step="0.01"
                                      class="text-right"
                                      :disabled="item.dynamic_amount"
                                      :formatter="numberFormatter"
                                      v-model.number="item.amount"></b-form-input>
                    </b-form-group>
                    <error-list v-if="!!errors.amount" :message-function="errorMessageFormatter" :errors="errors.amount"></error-list>
                </div>
                <div class="col-3">
                    <b-form-group class="pt-1">
                        <b-checkbox v-model="item.dynamic_amount">Динамическая цена</b-checkbox>
                    </b-form-group>
                    <error-list v-if="!!errors.dynamic_amount" :message-function="errorMessageFormatter" :errors="errors.dynamic_amount"></error-list>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <b-form-group description="Описание">
                        <textarea v-model="item.description" class="form-control"></textarea>
                    </b-form-group>
                    <error-list v-if="!!errors.description" :message-function="errorMessageFormatter" :errors="errors.description"></error-list>
                </div>
                <div class="col-6">
                    <b-form-group description="Применимо к">
                        <work-position-select v-model="item.workPosition"></work-position-select>
                    </b-form-group>
                    <error-list v-if="!!errors.work_position_id" :message-function="errorMessageFormatter" :errors="errors.work_position_id"></error-list>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-6 text-left">
                    <button :disabled="loading" @click="$emit('close')" class="btn btn-danger">Отмена</button>
                </div>
                <div class="col-6 text-right">
                    <button @click="save()" :disabled="loading" class="btn btn-success">
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {BountyTypeMixin} from '@mixins';
    import WorkPositionSelect from '../../../_common/WorkPositionSelect';
    import ErrorList from '../../../_common/ErrorList';

    export default {
        name: "CreateEditForm",
        mixins: [BountyTypeMixin],
        components: {WorkPositionSelect, ErrorList},
    }
</script>

<style>

</style>
