<template>
    <div class="card mb-0">
        <div class="card-header">
            <button @click="create()" class="btn btn-success"><i class="fas fa-plus"></i> Добавить</button>
        </div>
        <div class="card-body mb-0">
            <div class="table-responsive">
                <table class="table table-sm table-striped table-bordered mb-0">
                    <thead class="thead-dark">
                    <tr>
                        <th>#</th>
                        <th>Тип</th>
                        <th>Название</th>
                        <th>Описание</th>
                        <th>Динамическая цена</th>
                        <th class="text-right">Стоимость</th>
                        <th>Применимо</th>
                        <th v-if="$auth.check([ROLE_ADMIN])"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="loadingList">
                        <td colspan="8">
                            <div class="alert alert-info text-center mb-0">
                                <i class="fas fa-spinner fa-spin"></i> Загрузка...
                            </div>
                        </td>
                    </tr>
                    <tr v-else-if="items.length === 0">
                        <td colspan="8">
                            <div class="alert alert-warning text-center mb-0">
                                Пусто
                            </div>
                        </td>
                    </tr>
                    <template v-else>
                        <tr v-for="(item, index) in items">
                            <td>{{item.id}}</td>
                            <td>{{item.sign < 0 ? 'Штраф' : 'Надбавка'}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.description}}</td>
                            <td>{{item.dynamic_amount ? 'Да' : 'Нет'}}</td>
                            <td class="text-right">{{item.dynamic_amount ? '-' : item.amount}}</td>
                            <td>{{item.workPosition ? 'к ' + item.workPosition.name : 'Ко всем'}}</td>
                            <td v-if="$auth.check([ROLE_ADMIN])" class="text-right">
                                <button @click="edit(item, index)" class="btn btn-sm"><i class="fas fa-edit"></i> Изменить</button>
                                <button @click="deleteItem(item, index)" class="btn btn-sm btn-danger"><i class="fas fa-times"></i> Удалить</button>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {ROLE_ADMIN} from "../../../../utils/Roles";
    import {SALARY_ENDPOINT} from "../../../../utils/endpoints";
    import CreateEditForm from "./CreateEditForm";
    import DeleteForm from "./DeleteForm";

    const WITH = [
        'workPosition'
    ];

    export default {
        name: "SalaryBountyTypes",
        data() {
            return {
                ROLE_ADMIN: ROLE_ADMIN,
                loadingList: false,
                loadingItem: false,
                items: [],
                modalParams: {
                    adaptive: true,
                    height: 'auto',
                    width: 900,
                    classes: 'overflowable'
                }
            };
        },
        methods: {
            create() {
                this.$modal.show(CreateEditForm, {
                    successCallback: (item => {
                        this.items.unshift(item);
                    }),
                    with: WITH
                }, this.modalParams, {})
            },
            edit(item, index) {
                this.$modal.show(CreateEditForm, {
                    successCallback: (item => {
                        this.items[index] = item;
                        this.$forceUpdate();
                    }),
                    with: WITH,
                    value: item,
                    index: index
                }, this.modalParams, {})
            },
            deleteItem(item, index) {
                this.$modal.show(DeleteForm, {
                    successCallback: (item => {
                        this.items.splice(index, 1);
                        this.$forceUpdate();
                    }),
                    with: WITH,
                    value: item,
                    index: index
                }, this.modalParams, {})
            },
            load() {
                if (this.loadingList) {
                    return;
                }

                this.loadingList = true;
                this.$http.get(SALARY_ENDPOINT + '/bounty/type', {
                    params: {
                        with: WITH,
                        orders: {
                            created_at: 'desc'
                        },
                        limit: 99,
                        without_loading: true
                    }
                }).then(response => {
                    this.items = response.data.data.data;
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    } else {
                        this.$toast.error('Ошибка');
                    }
                }).finally(() => {
                    this.loadingList = false;
                });
            },
        },
        mounted() {
            this.load();
        }
    }
</script>
